/** @jsx jsx */
import { Message, jsx } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";
import { ActionButton, Icon } from "office-ui-fabric-react";
import DefaultLayout from "../../../layouts/dlw-mobile";
import KanhaVolunteering from "../../../components/AshramOffice/KanhaVolunteering";

const KanhaVolunteeringPage = () => {
  const [q] = useQueryParam("q", StringParam);

  return (
    <DefaultLayout>
      {/* <DefaultButton text="My Entries" href="./my-entries/" />
      <DefaultButton text="New Entries" href="./new-entries/" /> */}
      {typeof q !== "string" && (
        <Message>
          <div>
            <Icon iconName="AlertSolid" /> Invalid Event Link{" "}
          </div>
          <ActionButton
            href="all"
            iconProps={{ iconName: "Forward" }}
            text="Click here to see All Active Events"
          />
        </Message>
      )}
      {typeof q === "string" && <KanhaVolunteering q={q} />}
    </DefaultLayout>
  );
};

export default KanhaVolunteeringPage;
